body {
    margin: 0;
    padding: 0;
    font-family: "Segoe UI", Arial, sans-serif;
    overflow-x: hidden;
}

.twitter-timeline {
    width: 80%;
    height: 500px;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

body::-webkit-scrollbar       {background-color:#fff;width:16px}
body::-webkit-scrollbar-track {background-color:#fff}
body::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:16px;border:4px solid #fff}